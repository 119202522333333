import React from "react"
import { Link } from "gatsby"

export default function Home() {
  return (
    <main>
      <div>Hello world!</div>
      <div><Link to="/about/">About</Link></div>
    </main>
    )
}
